import React, { FC, PropsWithChildren } from 'react';
import { useSettings } from '@wix/tpa-settings/react';
import settingsParams from '../../../settingsParams';
import { st, classes } from './Card.st.css';

export const Card: FC<PropsWithChildren<{ displayAllContent: boolean }>> = ({
  displayAllContent,
  children,
}) => {
  const settings = useSettings();

  const isSectionHeightEnabled = settings.get(settingsParams.isSectionHeightEnabled);
  const shouldWrap = isSectionHeightEnabled && !displayAllContent;

  return shouldWrap ? <div className={st(classes.card)}>{children}</div> : <>{children}</>;
};
