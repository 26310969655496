import React from 'react';
import { useSettings } from '@wix/tpa-settings/react';
import settingsParams from '../../../settingsParams';
import { st, classes } from './ExpandCollapseButton.st.css';

export const ExpandCollapseButton = ({
  isCollapsed,
  toggleIsCollapsed,
}: {
  isCollapsed: boolean;
  toggleIsCollapsed: (isCollapsed: boolean) => void;
}) => {
  const settings = useSettings();

  return (
    <button className={st(classes.button)} onClick={() => toggleIsCollapsed(!isCollapsed)}>
      {settings.get(isCollapsed ? settingsParams.expandLabel : settingsParams.collapseLabel)}
    </button>
  );
};
