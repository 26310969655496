import React, { useState } from 'react';
import {
  withExperiments,
  InjectedExperimentsProps,
  useTranslation,
  useEnvironment,
  useBi,
} from '@wix/yoshi-flow-editor';
import { useSettings, useStyles } from '@wix/tpa-settings/react';

// With Loadable support
// Currently loadable css doesnt work lol
import {
  WixRicosViewer,
  pluginEmojiViewer as pluginEmoji,
  pluginHashtagViewer as pluginHashtag,
  pluginMentionsViewer as pluginMentions,
  pluginSpoilerViewer as pluginSpoiler,
  pluginTextColorViewer as pluginTextColor,
  pluginTextHighlightViewer as pluginTextHighlight,
  pluginLinkButtonViewer as pluginLinkButton,
} from '@wix/ricos';

import {
  pluginDividerLoadable as pluginDivider,
  pluginVerticalEmbedLoadable as pluginVerticalEmbed,
  pluginCollapsibleListLoadable as pluginCollapsibleList,
  pluginFileUploadLoadable as pluginFileUpload,
  pluginGalleryLoadable as pluginGallery,
  pluginGiphyLoadable as pluginGiphy,
  pluginHtmlLoadable as pluginHtml,
  pluginImageLoadable as pluginImage,
  pluginLinkLoadable as pluginLink,
  pluginLinkPreviewLoadable as pluginLinkPreview,
  pluginPollLoadable as pluginPoll,
  pluginTableLoadable as pluginTable,
  pluginVideoLoadable as pluginVideo,
  pluginAudioLoadable as pluginAudio,
} from '@wix/ricos/loadable';

import '@wix/ricos/css/ricos-viewer.global.css';
import '@wix/ricos/css/plugin-hashtag-viewer.global.css';
import '@wix/ricos/css/plugin-mentions-viewer.global.css';
import '@wix/ricos/css/plugin-spoiler-viewer.global.css';
import '@wix/ricos/css/plugin-button-viewer.global.css';

import settingsParams from '../settingsParams';
import { Card } from './Components/Card';
import { ExpandCollapseButton } from './Components/ExpandCollapseButton';
import { CONTENT_QUESTION_OPTIONS } from '../consts';
import { getEmptyContent } from './getEmptyStateContent';
import { st, classes } from './Widget.st.css';
import { getTheme } from './theme';
import { WidgetProps } from '../types';

export default withExperiments<InjectedExperimentsProps & WidgetProps>(
  ({
    experiments,
    className,
    content,
    host,
    locale,
    isMobile = false,
    seoSettings,
    instance,
    metaSiteId,
  }) => {
    const [isCollapsed, toggleIsCollapsed] = useState(true);
    const { isViewer } = useEnvironment();
    const { t } = useTranslation();
    const settings = useSettings();
    const styles = useStyles();
    const logger = useBi();

    const isEmptyContent = !content;

    if (isEmptyContent && isViewer) {
      return null;
    }

    const normalizedContent = !content
      ? getEmptyContent(t('app.content.emptyStateTitle'), t('app.content.emptyStateLabel'))
      : content;

    const shouldTruncate =
      settings.get(settingsParams.displayContent) !== CONTENT_QUESTION_OPTIONS.ALL && isCollapsed;

    return (
      <div className={st(classes.root, { isMobile }, className)} data-hook="ricosviewer-wrapper">
        <Card displayAllContent={!shouldTruncate}>
          <WixRicosViewer
            theme={getTheme(styles, host.id)}
            plugins={[
              pluginCollapsibleList(),
              pluginDivider(),
              pluginEmoji(),
              pluginGiphy(),
              pluginHashtag(),
              pluginImage(),
              pluginGallery(),
              pluginVideo(),
              pluginAudio(),
              pluginFileUpload(),
              pluginLink(),
              pluginMentions(),
              pluginTable(),
              pluginTextColor(),
              pluginTextHighlight(),
              pluginHtml(),
              pluginLinkPreview(),
              pluginVerticalEmbed({ slimLayout: host.dimensions.width <= 500 }),
              pluginSpoiler(),
              pluginPoll(),
              pluginLinkButton(),
            ]}
            biSettings={{
              consumer: 'ricos-ooi-viewer',
              platform: 'Livesite',
              postId: content?.metadata?.id ?? 'ricos-viewer-ooi-post',
              containerId: '949cfcc9-2a3f-4a96-bd6c-11d9d82763a4',
              logger,
            }}
            content={normalizedContent}
            wixExperiments={experiments.all()}
            locale={locale}
            isMobile={isMobile}
            seoSettings={seoSettings}
            instance={instance}
            mediaSettings={{ fullscreenProps: { container: () => document.body } }}
            {...(metaSiteId && { iframeSandboxDomain: `https://${metaSiteId}.filesusr.com` })}
          />
        </Card>
        {!isEmptyContent &&
          settings.get(settingsParams.displayContent) === CONTENT_QUESTION_OPTIONS.WITH_EXPAND && (
            <ExpandCollapseButton isCollapsed={isCollapsed} toggleIsCollapsed={toggleIsCollapsed} />
          )}
      </div>
    );
  },
);
