export const BACKGROUND_ROUTE = 'background_route';
export const TEXTS_ROUTE = 'texts_route';
export const BUTTON_LINKS_ROUTE = 'button&links_route';
export const MAIN_ROUTE = 'main';
export const PX = 'px';
export const DEFAULT_TAB = 'default_tab';
export const HOVER_TAB = 'hover_tab';
export const CUSTOM_PRESET = 'Custom';
export const MAX_BG_PADDINGS = 400;
export const MAX_BG_CORNER_RADIUS = 1200;
export const MAX_BG_BORDER_WIDTH = 20;
export const MAX_BUTTON_BORDER_WIDTH = 20;
export const MAX_BUTTON_CORNER_RADIUS = 20;
export const FONT_SIZE_MIN = 12;
export const FONT_SIZE_MAX = 200;
export const CONTENT_QUESTION_OPTIONS = {
  ALL: 'all',
  SHORTENED_CONTENT: 'shortenedContent',
  WITH_EXPAND: 'withExpand',
};
