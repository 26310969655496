import { StyleParamType, wixColorParam, createStylesParams } from '@wix/tpa-settings';

type IStylesParams = {
  bgColor: StyleParamType.Color;
  paddingsTopBottom: StyleParamType.Number;
  paddingsLeftRight: StyleParamType.Number;
  cornerRadius: StyleParamType.Number;
  borderColor: StyleParamType.Color;
  borderWidth: StyleParamType.Number;
};

export default createStylesParams<IStylesParams>({
  bgColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1'),
  },
  paddingsTopBottom: {
    type: StyleParamType.Number,
    getDefaultValue: () => 20,
  },
  paddingsLeftRight: {
    type: StyleParamType.Number,
    getDefaultValue: () => 20,
  },
  cornerRadius: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  borderColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1'),
  },
  borderWidth: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
});
