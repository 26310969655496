import _extends from "@babel/runtime/helpers/extends";
import { DEFAULTS } from "./defaults";
import { nodeViewDecorators } from "./nodeViewDecorators";
import { LINK_TYPE } from "./types";
var pluginLink = function pluginLink(config) {
  return {
    config: _extends({}, DEFAULTS.config, config),
    type: LINK_TYPE,
    nodeViewDecorators: nodeViewDecorators
  };
};
export { pluginLink };
import "@wix/ricos/css/plugin-link-viewer.global.css";