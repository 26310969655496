import { createSettingsParams, SettingsParamType } from '@wix/tpa-settings';
import { CONTENT_QUESTION_OPTIONS } from '../../consts';

export type ISettingsParams = {
  displayContent: SettingsParamType.String;
  numBlocksToShow: SettingsParamType.Number;
  isSectionHeightEnabled: SettingsParamType.Boolean;
  expandLabel: SettingsParamType.String;
  collapseLabel: SettingsParamType.String;
};

export default createSettingsParams<ISettingsParams>({
  displayContent: {
    getDefaultValue: () => CONTENT_QUESTION_OPTIONS.ALL,
  },
  numBlocksToShow: {
    getDefaultValue: () => 2,
  },
  isSectionHeightEnabled: {
    getDefaultValue: () => false,
  },
  expandLabel: {
    getDefaultValue: ({ t }) => t('app.settings.tabs.display.labelForExpand.expandButtonLabel'),
  },
  collapseLabel: {
    getDefaultValue: ({ t }) => t('app.settings.tabs.display.labelForCollapse.collapseButtonLabel'),
  },
});
